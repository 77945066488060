import "echarts-liquidfill/src/liquidFill.js";
import * as echarts from "echarts"
import moment from "moment";
import Util from "@/utils/utils";
import { getOperatePlanReach, exportAllInfoList, reportUnionAll } from "./api";

export default {

  data() {
    return {
      activeNameStyle: "height: 66px; overflow: hidden;",
      showNameStyle: "min-height: 66px;",
      isShowNameType: true,
      activeNameStyle1: "height: 66px; overflow: hidden;",
      showNameStyle1: "min-height: 66px;",
      isShowNameType1: true,
      activeNameIndex: null,
      aticity: false,
      navList: [],
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "",
          name: "/specialStoreCE",
          title: "专卖店综合评价"
        }
      ],
      filForm: {},
      isLoading: false,
      tableData: [],
      zonghedefr: 0.0,
      pageLoadFlag: false,
      paiming: "",
      custnum:'--',
      currentPreviousDay:'',
      NetIDN:{},// 分销网络净增数据
      DSRDNetwork:{},// 分销网络动销率
      SingleStoreOutput:{}, //分销网络单店产出
      IncrementalBusiness:{},//增量业务
      PDedicatedAircraft:{},//专供机占比
      channelObj:{},// 全渠道
      PMHEnd:{}, // 中高端占比
      monishuju:[
        {
          name1:111,
          name2:80,
          name3:131,
        },
        {
          name1:222,
          name2:70,
          name3:44,
        },
        {
          name1:444,
          name2:50,
          name3:4,
        },
        {
          name1:444,
          name2:50,
          name3:4,
        },
        {
          name1:444,
          name2:50,
          name3:4,
        },
        {
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },
        {
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },








      ],
      customerNum:'',// 商家总数
      comprehensiveRanking:'', // 排名
      comprehensiveScore:0,// 综合得分
      pieData:[
         {value: 0, name: '全渠道口径出货(60) '},
          {value:0, name: '分销网拓展与维护(20)'},
          {value: 0, name: '增值业务(前置渠道)(5)'},
          {value: 0, name: '销售结构(15) '},

      ],// 饼图数据
    };
  },
  created() {
    this.filForm.date = moment(new Date()).format("YYYY-MM");
  },
  mounted() {
    // this.getRepostData();
    // this.getYesterday()
    this.drawChartsone()
    this.drawChartstwoEcharts()
    // this.drawStudyCharts()
    // this.drawChartfiveEcharts()
    // this.drawChartsixEcharts()
    this.lastEcharts()

  },
  methods: {
    dateChange(e) {
      this.filForm.date = moment(e._d).format("YYYY-MM");
      this.getRepostData();

    },
    // 第一个div里面的图表
    drawChartsone() {
      var myChart = this.$echarts.init(this.$refs.oneEcharts);
      this.$forceUpdate()
      let chartdata = [30];
      let max = 1000;
      let option={
        backgroundColor:"#F4F0FF",
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: "category",
            data: [""],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            selectedMode: false,
            legendHoverLink:false,
            hoverAnimation: false,
            label:{
              show:true,
              backgroundColor:'#fff',
              width:40,
              height:20,
              position:'right',
              offset:[-26,0],
              borderWidth:1,
              borderColor:'#888AEA',
              borderRadius:10,
              shadowColor:'none',
              shadowBlur:0,
              formatter:function(param){
                return param.value + '%';
              },
              textStyle:{
                color: '#888AEA',
                fontSize:'12',
                // padding: [0, 0, 0, 6],
                align:'center',
                top:'middle',
              }
            },
            showBackground: false,
            silent: false,
            // barWidth: 14,
            itemStyle: {
              normal: {
                barBorderRadius: 14,
                color: '#8F98FF'
              },

            },
            barWidth: 14,
            data: [70]
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 14,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    // 第一个div里面的图表
    drawChartstwoEcharts() {
      var myChart = this.$echarts.init(this.$refs.two2Echarts);
      this.$forceUpdate()
      let chartdata = [30];
      let max = 1000;
      let option={
        backgroundColor:"#ECF8F8",
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: "category",
            data: [""],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            selectedMode: false,
            legendHoverLink:false,
            hoverAnimation: false,
            label:{
              show:true,
              backgroundColor:'#fff',
              width:40,
              height:20,
              position:'right',
              offset:[-26,0],
              borderWidth:1,
              borderColor:'#18D1BC',
              borderRadius:10,
              shadowColor:'none',
              shadowBlur:0,
              formatter:function(param){
                return param.value + '%';
              },
              textStyle:{
                color: '#18D1BC',
                fontSize:'12',
                // padding: [0, 0, 0, 6],
                align:'center',
                top:'middle',
              }
            },
            showBackground: false,
            silent: false,
            // barWidth: 14,
            itemStyle: {
              normal: {
                barBorderRadius: 14,
                color: '#18D1BC'
              },

            },
            barWidth: 14,
            data: [70]
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 14,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    // 第三个图标
    drawCharts() {
      var myChart = this.$echarts.init(this.$refs.three);
      var dataA = this.pieData
      this.$forceUpdate()
      let option =
        {
          color: [new echarts.graphic.LinearGradient(1, 1, 0, 0, [
            {
              offset: 0,
              color: '#8385E9'
            },
            {
              offset: 0.9,
              color: '#BFC1F7'
            }
          ]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#22C7BB'
            },
              {
                offset: 0.9,
                color: '#9DFDD5'
              }]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#F36556'
            },
              {
                offset: 0.9,
                color: '#FFAF9B'
              }]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#F69A52'
            },
              {
                offset: 0.9,
                color: '#F8CA6C'
              }]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#8EA2C2'
            },
              {
                offset: 0.9,
                color: '#BDC2D2'
              }])
          ],
          legend: {
            selectedMode: false,
            icon: "circle",
            left: "55%",
            y: "center", //延Y轴居中
            // x: '30%', //居右显示
            // orient: "vertical",
            color: "#ffffff",
            right: 20, //右边距0
            top: '50%',
            // icon: "pie",
            itemWidth: 8, // 设置宽度
            itemHeight: 8, // 设置高度
            // itemGap: this.$util.setFont(12), // 设置间距
            itemGap: 16, // 设置间距
            formatter: function(name) {
              var value;
              for (let i = 0, l = dataA.length; i < l; i++) {
                if (dataA[i].name == name) {
                  value = dataA[i].value;
                }
              }
              var str = "{a|" + name + "}{b|" + value + "}";
              return str;
            },
            textStyle: {
              rich: {
                a: {
                  color: "#777",
                  fontSize: 14,
                  width: 160,
                  padding: [0, 0, 0, 2] //间距
                },
                b: {
                  color: "#262626",
                  fontSize: 14,
                  // padding: [0, 0, 16, 2] //间距
                }
              }
            }
          },
          series: [
            {
              // hoverAnimation: false,
              name: '',
              type: 'pie',
              center: ['30%', '60%'],
              radius: ['49%', '62%'],
              avoidLabelOverlap: false,
              clockWise: false,
              itemStyle: {
                normal: {
                  borderColor: '#f7f7f7',
                  borderWidth: 4
                }
              },
              label: {
                show: false,
                position: 'center'
              },

              labelLine: {
                normal: {
                  show: false
                }
              },
              data: this.pieData
            },
            // 边框的设置
            {
              hoverAnimation: false,
              radius: ['44%', '46%'],
              center: ['30%', '60%'],
              type: 'pie',
              label: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              labelLine: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              animation: false,
              tooltip: {
                show: false
              },
              data: [{
                value: 1,
                itemStyle: {
                  color: "#C9EEF2",
                },
              }],
            },
            // 内圆设置
            {
              radius: [0, '45%'],
              center: ['30%', '60%'],
              hoverAnimation: false,
              type: 'pie',
              label: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              labelLine: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              animation: false,
              tooltip: {
                show: false
              },
              data: [{
                value: 1,
                itemStyle: {
                  normal: {
                    color: { // 完成的圆环的颜色
                      colorStops: [{
                        offset: 0,
                        color: "#F9FDFE" // 0% 处的颜色
                      }, {
                        offset: 0.4,
                        color: "#E0F6F8" // 100% 处的颜色
                      }]
                    },
                    label: {
                      show: false
                    },
                    labelLine: {
                      show: false
                    }
                  }
                },
              }],
            }
          ]
        }

      myChart.setOption(option);
    },
    // 学习综合得分
    drawStudyCharts() {
      var myChart = this.$echarts.init(this.$refs.studyZH);
      var echartData = [{
        value: 5,
        name: '曲阜师范大学'
      }, {
        value: 5,
        name: '潍坊学院'
      }]
      let  option = {
        // backgroundColor: '#ECF8F8',
        series: [{
          name: '',
          type: 'pie',
          radius: ['58%', '70%'],
          hoverAnimation: false,
          color: ['#1890FF', '#18D1BC'],
          avoidLabelOverlap: false,
          clockWise: false,label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            normal: {
              show: false
            },
            emphasis: {
              show: false
            }
          },
          data: echartData
        }]
      };
      myChart.setOption(option);

    },
    // 学习综合第一个
    drawChartfiveEcharts() {
      var myChart = this.$echarts.init(this.$refs.fiveEcharts);
      this.$forceUpdate()
      let chartdata = [30];
      let max = 1000;
      let option={
        backgroundColor:"#F4F0FF",
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: "category",
            data: [""],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            selectedMode: false,
            legendHoverLink:false,
            hoverAnimation: false,
            label:{
              show:false,
            },
            showBackground: false,
            silent: false,
            itemStyle: {
              normal: {
                barBorderRadius: 20,
                color: '#18D1BC'
              },

            },
            barWidth: 20,
            data: [70]
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 20,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    // 学习综合第er个
    drawChartsixEcharts() {
      var myChart = this.$echarts.init(this.$refs.sixEcharts);
      this.$forceUpdate()
      let chartdata = [30];
      let max = 1000;
      let option={
        backgroundColor:"#F4F0FF",
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: "category",
            data: [""],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            selectedMode: false,
            legendHoverLink:false,
            hoverAnimation: false,
            label:{
              show:false,
            },
            showBackground: false,
            silent: false,
            itemStyle: {
              normal: {
                barBorderRadius: 20,
                color: '#18D1BC'
              },

            },
            barWidth: 20,
            data: [70]
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 20,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    getYesterday() {
      let day1 = new Date();
      day1.setTime(day1.getTime()-24*60*60*1000);
      this.currentPreviousDay = day1.getFullYear()+"/" + (day1.getMonth()+1) + "/" + day1.getDate();
    },
    gotoTable() {
      let routeUrl = this.$router.resolve({
        path: "/reports/operation"

      });
      window.open(routeUrl.href, "_blank");
    },
    getRepostData(){
      this.pageLoadFlag = true;
      let data = {
        queryTime	: this.filForm.date,
        cisCode: this.$store.state.user.userInfo.customer.cisCode
      }
      getOperatePlanReach(data).then(res=>{
        //分销网络净增
        this.NetIDN = {}
        //分销网络动销率
        this.DSRDNetwork = {}
        //分销网络单店产出
        this.SingleStoreOutput = {}
        //增量业务(前置渠道)
        this.IncrementalBusiness = {}
        //全渠道口径出货
        this.channelObj = {}
        // 中高端占比
        this.PMHEnd = {}
        // 专供机占比
        this.PDedicatedAircraft = {}
        if(res.data.code == 0) {
          // 商家总数
          this.customerNum =  res.data.data.customerNum ?res.data.data.customerNum: '--'
          // 排名
          this.comprehensiveRanking = res.data.data.comprehensiveRanking ?res.data.data.comprehensiveRanking: '--'
          // 饼图数据
          this.pieData = []
          if(res.data.data.summaryScoreList && res.data.data.summaryScoreList.length > 0){
            res.data.data.summaryScoreList.forEach(item=>{
              item.fullScore = parseInt(item.fullScore)
              this.pieData.push({
                value:item.score,
                name:item.name+'('+item.fullScore+')'
              })
            })
          }  else  {
            this.pieData = [
              {value: 0, name: '月度履约进度(30) '},
              {value:0, name: '学习综合得分(20)'},
              {value: 0, name: '专供产品占比(5)'},
              {value: 0, name: '中高端产品占比(15) '},
              {value: 0, name: '库存周转天数(20) '},
            ]
          }

          // 综合得分
          this.comprehensiveScore = res.data.data.comprehensiveScore ?res.data.data.comprehensiveScore: '--'
          if(res.data.data.individualEvaluations && res.data.data.individualEvaluations.length > 0) {
            res.data.data.individualEvaluations.forEach(item=>{
              if(item.functionName == '分销网络净增') {
                this.NetIDN = item
              }
              if(item.functionName == '分销网络动销率') {
                this.DSRDNetwork = item
              }
              if(item.functionName == '分销网络单店产出') {
                this.SingleStoreOutput = item
              }
              if(item.functionName==' 增量业务(前置渠道)' || item.functionName=='增量业务(前置渠道)') {
                this.IncrementalBusiness = item
              }
              if(item.functionName == '全渠道口径出货') {
                this.channelObj = item
              }
              if(item.functionName == '高中端占比') {
                this.PMHEnd = item
              }
              if(item.functionName == '专供机占比') {
                this.PDedicatedAircraft = item
              }
            })
          }
          this.drawCharts()
          this.pageLoadFlag = false

        }else {
          this.pieData = [
            {value: 0, name: '月度履约进度(30) '},
            {value:0, name: '学习综合得分(20)'},
            {value: 0, name: '专供产品占比(5)'},
            {value: 0, name: '中高端产品占比(15) '},
            {value: 0, name: '库存周转天数(20) '},
          ]
          this.drawCharts()
        }
      }).catch(error=>{
        this.pageLoadFlag = false;
      })
    },
    lastEcharts(){
      var myChart = this.$echarts.init(this.$refs.lastEcharts);
      let option = {
        series: [{
          name: "",
          type: "pie",
          radius: '68%',
          center: ['50%', '50%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: [{
            value: 100,
            name: "设计单位"
          },
            {
              value: 90,
              name: "基建单位"
            },

          ],
          itemStyle: {
            normal: {
              borderWidth: 3,
              borderColor: '#ffffff',
            },

          },
          color: [
            '#727EFF',
            '#FF8A8A',

          ],
        }],
      };
      myChart.setOption(option);
    }
  }
};
