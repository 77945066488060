import http from "@/utils/request";

//运营数据
export function getOperatePlanReach(data) {
    return http({
        method: "get",
        // url: "/coreFunction/findEvaluation.nd",
        url:'/evaluation/findComprehensiveEvaluation.nd',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        params:data
    });
}


//合并数据导出
export function exportAllInfoList(data) {
    return http({
        method: "post",
        url: "/customer/check/export.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        responseType: 'blob',
        data
    });
}
export function reportUnionAll(data) {
    return http({
        method: "post",
        url: "/fast/userReport/reportUnionAll/export.nd",
        // contentType: "application/json;charset=UTF-8",
        // type: 'stringfy',
        responseType: 'blob',
        data
    });
}

